
export default {
  name: 'ErrorDefault',

  props: {
    error: {
      type: Object,
      required: true,
    },
  },

  head () {
    return {
      title: `${this.$t('Error code', { code: this.error.statusCode })} | ${this.$config.storeName}`,
    }
  },
}
