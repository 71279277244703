import { render, staticRenderFns } from "./MediClubRegisterHorizontalBlock.vue?vue&type=template&id=1fbfaaba&scoped=true&"
var script = {}
import style0 from "./MediClubRegisterHorizontalBlock.vue?vue&type=style&index=0&id=1fbfaaba&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fbfaaba",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseSpacer: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseSpacer.vue').default,MediClubLogo: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/MediClub/MediClubLogo.vue').default,BaseButton: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseButton.vue').default,BaseSheet: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseSheet.vue').default,BaseContainer: require('/home/gitlab-runner/builds/Dz_K4MD8/0/strix/frontend/apps/medicover-pl/medistore-headless-app/components/base/BaseContainer.vue').default})
